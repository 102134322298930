import { useEffect, useState } from "react";
import { debounce } from "lodash";
import routes from "../json/routes";
import counties from "../json/toc";

const SearchData = (props) => {
  const [word, setWord] = useState("");
  const [basedOn, setBasedOn] = useState("");
  const [route, setRoute] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "word":
        setWord(value);
        break;
      case "based_on":
        setBasedOn(value);
        break;
      case "route":
        setRoute(value);
        break;
      default:
        break;
    }
  };

  const debouncedTextData = debounce(handleChange, 500);
  const debouncedData = debounce(handleChange, 200);
  useEffect(() => {
    const data = { word: word, based_on: basedOn, route: route };
    const dataString = JSON.stringify(data);
    props.sendData(dataString);
  }, [word, basedOn, route]);

  return (
    <>
      <div className="relative lg:hidden">
        <div className="pointer-events-none overflow-hidden  sm:-mb-11 sm:pb-11 lg:-mb-12 lg:pb-12">
          <div className="relative">
            <div className="pointer-events-auto relative bg-white border-b border-blue-950 sm:pb-0">
              <div className="mx-auto max-w-7xl sm:px-12 px-8 lg:px-8 flex flex-col">
                <div className="relative flex-auto  my-2">
                  <input
                    type="search"
                    aria-label="Search a Keyword"
                    placeholder="Search a Keyword..."
                    class="block w-full appearance-none bg-transparent pr-4 pl-9 py-4 text-base text-slate-900 transition focus:ring-2 focus:ring-blue-950 placeholder:text-slate-400 focus:outline-none sm:text-[0.8125rem] sm:leading-6 [&amp;::-webkit-search-decoration]:appearance-none [&amp;::-webkit-search-cancel-button]:appearance-none [&amp;::-webkit-search-results-button]:appearance-none [&amp;::-webkit-search-results-decoration]:appearance-none"
                    list="word_data"
                    name="word"
                    id="word"
                    onChange={debouncedTextData}
                  />
                  <datalist id="word_data">
                    {counties.map((county, index) => (
                      <option key={index} value={county}></option>
                    ))}
                  </datalist>
                  <svg
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 fill-slate-900 transition pb-1"
                  >
                    <path d="M16.72 17.78a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM9 14.5A5.5 5.5 0 0 1 3.5 9H2a7 7 0 0 0 7 7v-1.5ZM3.5 9A5.5 5.5 0 0 1 9 3.5V2a7 7 0 0 0-7 7h1.5ZM9 3.5A5.5 5.5 0 0 1 14.5 9H16a7 7 0 0 0-7-7v1.5Zm3.89 10.45 3.83 3.83 1.06-1.06-3.83-3.83-1.06 1.06ZM14.5 9a5.48 5.48 0 0 1-1.61 3.89l1.06 1.06A6.98 6.98 0 0 0 16 9h-1.5Zm-1.61 3.89A5.48 5.48 0 0 1 9 14.5V16a6.98 6.98 0 0 0 4.95-2.05l-1.06-1.06Z"></path>
                  </svg>
                </div>
                <div className="lg:hidden">
                  <div
                    aria-hidden="true"
                    class="grid grid-cols-3 gap-0.5 rounded-lg bg-slate-400/10 text-center text-[0.8125rem] font-semibold leading-6 text-slate-600 shadow-[0_1px_2px_rgba(15,23,42,0.04)] ring-1 ring-slate-900/5"
                    id="basedon"
                  >
                    <div class="py-2 px-6 transition focus:z-10 [&amp;:not(:focus-visible)]:focus:outline-none rounded-l-lg  bg-white hover:bg-slate-50 text-violet-500">
                      <input
                        type="radio"
                        id="based_on_toc"
                        name="based_on"
                        value="toc"
                        onChange={debouncedData}
                        className="hidden peer"
                      />
                      <label
                        htmlFor="based_on_toc"
                        // className="flex items-center cursor-pointer"
                      >
                        <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                          Town/City
                        </span>
                      </label>
                    </div>
                    <div class="py-2 px-6 transition focus:z-10 [&amp;:not(:focus-visible)]:focus:outline-none bg-white hover:bg-slate-50 hover:text-slate-700">
                      <input
                        type="radio"
                        id="based_on_c"
                        name="based_on"
                        value="c"
                        onChange={debouncedData}
                        className="hidden peer"
                      />{" "}
                      <label
                        htmlFor="based_on_c"
                        // className="flex items-center cursor-pointer"
                      >
                        <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                          County
                        </span>
                      </label>
                    </div>
                    <div className="py-2 px-6 transition focus:z-10 [&amp;:not(:focus-visible)]:focus:outline-none rounded-r-lg bg-white hover:bg-slate-50 hover:text-slate-700">
                      <input
                        type="radio"
                        id="based_on_org"
                        name="based_on"
                        value="org"
                        onChange={debouncedData}
                        className="hidden peer"
                      />{" "}
                      <label
                        htmlFor="based_on_org"
                        // className="flex items-center cursor-pointer text-center"
                      >
                        <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                          Org
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="lg:hidden my-2">
                  <select
                    id="route"
                    name="route"
                    onChange={debouncedData}
                    class="block w-full  bg-transparent py-4 px-4 text-base text-slate-900 transition placeholder:text-slate-400 focus:ring-2 focus:ring-blue-950 focus:outline-none sm:text-[0.8125rem] sm:leading-6 [&amp;::-webkit-search-decoration]:appearance-none [&amp;::-webkit-search-cancel-button]:appearance-none [&amp;::-webkit-search-results-button]:appearance-none [&amp;::-webkit-search-results-decoration]:appearance-none "
                  >
                    <option value="">Select Category</option>
                    {routes.map((route, index) => (
                      <option key={index} value={route}>
                        {route}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block border-b border-blue-950 py-10 px-20 bg-white">
        <div className="lg:max-w-6xl lg:mx-auto">
          <div className="lg:flex-row my-3 flex-col flex ">
            <div className="my-2 relative group w-full  mr-2" id="word_big">
              <svg
                width="20"
                height="20"
                className="absolute left-3 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                />
              </svg>
              <input
                type="search"
                className="peer w-full py-2 pl-10 px-4  border-none focus:ring-2 focus:ring-blue-950 focus:outline-none appearance-none text-sm leading-7 text-slate-900 placeholder-slate-400 ring-1 ring-slate-200 shadow-sm"
                list="word_data"
                name="word"
                id="word"
                placeholder="Search a Keyword"
                onChange={debouncedTextData}
              />
              <datalist id="word_data">
                {counties.map((county, index) => (
                  <option key={index} value={county}></option>
                ))}
              </datalist>
            </div>
            <div className="my-2 ">
              <div
                className="lg:mt-0 lg:flex-shrink-0 z-10 ml-2"
                id="route_big"
              >
                <select
                  id="route"
                  name="route"
                  className="items-center text-center py-2  px-4 focus:ring-2 focus:ring-blue-950 focus:outline-none appearance-none text-sm leading-7 text-slate-900 placeholder-slate-400  ring-1 ring-slate-200 shadow-sm"
                  onChange={debouncedData}
                  required
                >
                  <option value="">Select Route</option>
                  {routes.map((route, index) => (
                    <option key={index} value={route}>
                      {route}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div
            className="flex flex-wrap justify-center items-center space-x-4 "
            id="basedon_big"
          >
            <label
              htmlFor="based_on_toc"
              className="flex items-center cursor-pointer"
            >
              <input
                type="radio"
                id="based_on_toc"
                name="based_on"
                value="toc"
                onChange={debouncedData}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Town/City
              </span>
            </label>

            <label
              htmlFor="based_on_c"
              className="flex items-center cursor-pointer"
            >
              <input
                type="radio"
                id="based_on_c"
                name="based_on"
                value="c"
                onChange={debouncedData}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                County
              </span>
            </label>

            <label
              htmlFor="based_on_org"
              className="flex items-center cursor-pointer"
            >
              <input
                type="radio"
                id="based_on_org"
                name="based_on"
                value="org"
                onChange={debouncedData}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Organization Name
              </span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
export default SearchData;
