const routes = [
  "Skilled Worker",
  "Creative Worker",
  "Global Business Mobility: Senior or Specialist Worker",
  "Tier 2 Ministers of Religion",
  "Global Business Mobility: UK Expansion Worker",
  "Global Business Mobility: Graduate Trainee",
  "International Sportsperson",
  "Charity Worker",
  "Religious Worker",
  "Government Authorised Exchange",
  "International Agreement",
  "Seasonal Worker",
  "Scale-up",
  "Global Business Mobility: Service Supplier",
  "Global Business Mobility: Secondment Worker",
];

export default routes;
