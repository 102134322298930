const TableHeader = [
  // {
  //   name: "Rank",
  //   cell: (row, rowIndex) => {
  //     return <div>{rowIndex + 1}</div>;
  //   },
  // },
  {
    name: "Organisation Name",
    selector: (row) => row["Organisation Name"],
    // wrap: true,
  },

  {
    name: "Town/City",
    selector: (row) => row["Town/City"],
  },
  {
    name: "County",
    selector: (row) => row["County"],

    hide: "md",
  },
  {
    name: "Type & Rating",
    selector: (row) => row["Type & Rating"],

    hide: "md",
  },
  { name: "Route", selector: (row) => row["Route"], right: true, hide: "sm" },
];

export default TableHeader;
