import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";
import steps from "./onboarding_steps";
import Heroimg from "../img/stamp visa.jpg";
// import Heroimg from "../img/visa.png";
// import Heroimg from "../img/passports.jpg";

const Hero = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [run, setRun] = useState(false);
  const [runSm, setRunSm] = useState(false);

  const handleOnboard = () => {
    setRun(true);
  };
  const handleOnboardSm = () => {
    setRunSm(true);
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <>
      <Joyride
        continuous
        callback={() => {}}
        run={run}
        steps={steps.step_big}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#172554",
          },
        }}
      />
      <Joyride
        continuous
        callback={() => {}}
        run={runSm}
        steps={steps.step_sm}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        showProgress
        spotlightClicks={true}
        styles={{
          options: {
            primaryColor: "#172554",
          },
        }}
      />
      <section className="flex flex-col-reverse text-center lg:items-stretch lg:flex-row lg:ustify-end relative lg:text-left overflow-x-hidden border-b border-gray-50 h-1/2 lg:py-12 lg:px-20">
        <div
          className={`${
            isVisible
              ? "flex flex-col justify-center lg:self-center lg:flex-grow lg:flex-shrink lg:w-1/2  w-full pr-10 pl-8 pt-2 pb-8 md:pr-15 md:pl-12 md:pt-7 md:pb-12 lg:px-16 lg:pt-8 lg:pb-16 xl:px-20 xl:pt-12 xl:pb-20 mx-auto max-w-3xl transition-transform duration-700 ease-in-out 2xl:px-28 2xl:pt-20 2xl:pb-28"
              : "opacity-0 scale-0"
          }`}
        >
          <h1 className="max-w-xl text-2xl leading-7 tracking-tight text-gray-900 font-bold font-sans text-center lg:text-4xl sm:max-w-full lg:text-left lg:leading-tight mb-5 sm:text-5xl">
            Simplify Your Job Search
          </h1>
          <p className="max-w-xl text-clip text-gray-500 lg:text-left lg:max-w-md text-xs sm:max-w-full lg:text-lg sm:text-xl sm:my-2 lg:my-5">
            Find UK Tier 2 Sponsors with Ease.
          </p>
          <div className="flex justify-center mt-6 lg:justify-start">
            <button
              type="button"
              className="hidden lg:block text-white bg-blue-950 font-normal rounded-lg px-3 py-4 text-center hover:bg-blue-900 hover:drop-shadow-md transition duration-300 ease-in-out text-xs sm:px-8 sm:py-6 sm:text-lg"
              onClick={handleOnboard}
            >
              GET STARTED
            </button>
            <button
              type="button"
              className="lg:hidden text-white bg-blue-950 font-normal rounded-lg px-3 py-4 text-center hover:bg-blue-900 hover:drop-shadow-md transition duration-300 ease-in-out text-xs sm:px-8 sm:py-6 sm:text-lg"
              onClick={handleOnboardSm}
            >
              GET STARTED
            </button>
          </div>
        </div>
        <div className="w-full max-w-full h-1/2 border-0 rounded-none inline-block relative lg:flex-grow-0 lg:flex-shrink-0 lg:w-1/2">
          <img
            src={Heroimg}
            alt="hero"
            className={`${
              isVisible
                ? "transition-opacity duration-700 ease-in-out block object-cover object-center w-full lg:object-right h-full rounded-none"
                : "opacity-0 scale-0"
            }`}
          />
        </div>
      </section>
    </>
  );
};

export default Hero;
