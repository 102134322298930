const steps = {
  step_big: [
    {
      content: (
        <div>
          <h2>Welcome to UKSponsors!</h2>{" "}
          <p className="py-5">
            {" "}
            Let's get started by showing you how easy it is to navigate our
            platform and discover the ideal sponsor for your needs.
          </p>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "center",
      target: "body",
    },
    {
      content: (
        <div>
          <h2>
            Enter a keyword that is relevant to the UK sponsor you are looking
            for.
          </h2>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: "#word_big",
      title: "Search Keyword",
    },
    {
      content: (
        <h2>
          Choose the category on which you would like to base your search.
        </h2>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: "#basedon_big",
      title: "Search based on",
    },
    {
      content: (
        <h2>
          Finally, select the route through which you would like to find your
          potential Tier 2 sponsors.
        </h2>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: "#route_big",
      title: "Select Route",
    },
  ],
  step_sm: [
    {
      content: (
        <div>
          <h2>Welcome to UKSponsors!</h2>{" "}
          <p className="py-5">
            {" "}
            Let's get started by showing you how easy it is to navigate our
            platform and discover the ideal sponsor for your needs.
          </p>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "center",
      target: "body",
    },
    {
      content: (
        <div>
          <h2>
            Enter a keyword that is relevant to the UK sponsor you are looking
            for.
          </h2>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: "#word",
      title: "Search Keyword",
    },
    {
      content: (
        <h2>
          Choose the category on which you would like to base your search.
        </h2>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: "#basedon",
      title: "Search based on",
    },
    {
      content: (
        <h2>
          Finally, select the route through which you would like to find your
          potential Tier 2 sponsors.
        </h2>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: "#route",
      title: "Select Route",
    },
  ],
};

export default steps;
