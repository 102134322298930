import Logo from "../img/default.png";
// import { ReactComponent as Logo } from "../img/default_4.svg";

const NavBar = () => {
  return (
    <>
      <nav className=" bg-blue-950 border-b border-gray-100 py-4 sm:py-8 lg:pl-32 lg:pr-16 sm:px-10 ">
        <div className="relative container flex items-center justify-between">
          <a href="/">
            <img src={Logo} alt="Logo" />
          </a>

          <a
            class="rounded-lg text-slate-900 font-semibold transition flex items-center gap-1 sm:gap-3  text-sm sm:text-[0.8125rem] leading-6 py-1 hover:bg-slate-900/[0.03] -my-1 mx-0 md:-mx-1.5 sm:text-lg"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/intent/tweet?text=Check%20out%20our%20UK%20Tier%202%20sponsorship%20finder%21%20Find%20the%20perfect%20sponsorship%20opportunity%20for%20your%20career%20growth.&url=https%3A%2F%2Fuksponsorship.info"
          >
            <svg
              viewBox="0 0 20 20"
              aria-hidden="true"
              class="h-5 w-5 fill-slate-400"
            >
              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0 0 20 3.92a8.19 8.19 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743 11.65 11.65 0 0 1-8.457-4.287 4.106 4.106 0 0 0 1.27 5.477A4.073 4.073 0 0 1 .8 7.713v.052a4.105 4.105 0 0 0 3.292 4.022 4.095 4.095 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.233 8.233 0 0 1 0 16.407a11.615 11.615 0 0 0 6.29 1.84"></path>
            </svg>
            <span className="text-white text-sm font-light">
              Share<span class="sr-only sm:not-sr-only"> on Twitter</span>
            </span>
          </a>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
