import { useEffect, useState, useRef } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import TableHeader from "./json/tableHeader";
import SearchData from "./components/searchData";
import NavBar from "./components/nav_bar";
import Spinner from "./loader";
import Hero from "./components/hero";

const App = () => {
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState(null);
  const [lastUpdate, setLastUpdate] = useState([]);
  const [dataLength, setDataLength] = useState(null);
  const [loading, setLoading] = useState(true);
  const [keyWord, setKeyWord] = useState([]);
  const [category, setCategory] = useState([]);
  const [basedOn, setBasedOn] = useState([]);

  function hasCompleteValues(json) {
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        if (json[key] === "" || json[key] === null || json[key] === undefined) {
          return false;
        }
      }
    }
    return true;
  }
  const fetchData = async () => {
    try {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const requestBody = JSON.parse(requestOptions["body"]);

      if (hasCompleteValues(requestBody))
        await fetch(
          "https://hvpfqi4bob.execute-api.eu-west-2.amazonaws.com/sponsorship-checker",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            var processedJsonString = result.replace(/NaN/g, "null");
            var parsedJson = JSON.parse(processedJsonString);
            var apiData = parsedJson.output_table;
            var lastUpdated = parsedJson.last_updated;

            setLastUpdate(lastUpdated);
            setKeyWord(requestBody["word"]);
            setCategory(requestBody["route"]);
            const base = requestBody["based_on"];
            setBasedOn(requestBody["based_on"]);
            switch (base) {
              case "toc":
                setBasedOn("Town/City");
                break;
              case "c":
                setBasedOn("County");
                break;
              case "org":
                setBasedOn("Organisation Name");
                break;
              default:
                break;
            }

            if (apiData) {
              setData(apiData);
              var dataLength = apiData.length;
              setDataLength(dataLength);
            }
          });
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [raw]);

  const handleRawData = (rawData) => {
    setRaw(rawData);
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  const ExpandedComponent = (props) => (
    <div className="px-4 py-4 text-xs bg-slate-100">
      <pre>Org Name:{props.data["Organisation Name"]}</pre>
      <pre>Town/City:{props.data["Town/City"]}</pre>
      <pre>County:{props.data["County"]}</pre>
      <pre>Type & Rating:{props.data["Type & Rating"]}</pre>
      <pre>Route:{props.data["Route"]}</pre>
    </div>
  );
  const noTableDataComponent = (
    <div className="text-center px-5">
      <p className="text-sm font-semibold tracking-tight text-gray-900 sm:text-lg">
        No data available to display at the moment.
      </p>
      <p className="mt-6 text-sm leading-8 text-gray-600 sm:text-lg">
        Please enter a keyword, select a category, and specify the search
        criteria
      </p>
    </div>
  );
  createTheme(
    "tableCustom",
    {
      divider: {
        default: "#073642",
      },
    },
    "light"
  );

  return (
    <>
      <NavBar />
      <Hero />
      <div>
        <div className="py-5 bg-white"></div>
        <div
          className="sticky top-0 z-50 mt-0 shadow-[0_1px_3px_rgba(15,23,42,0.08)]"
          id="my-element"
        >
          <SearchData sendData={handleRawData} />
        </div>

        <div>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <div>
                {lastUpdate && (
                  <div className="px-5">
                    <div className="mx-auto max-w-2xl pt-8 pb-5 sm:py-6 lg:py-6">
                      <div className="text-center">
                        <h1 className="text-md font-bold tracking-tight text-gray-900 sm:text-2xl">
                          {dataLength} matches for "{keyWord}"
                        </h1>
                        <p className="mt-2 text-xs sm:text-lg leading-8 text-gray-600">
                          {" "}
                          in the{" "}
                          <span className="text-sm font-bold text-gray-900">
                            {category}
                          </span>{" "}
                          category based on{" "}
                          <span className="text-sm font-bold text-gray-900">
                            {basedOn}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="pt-8 lg:px-16 sm:px-8 text-base font-semibold leading-7 text-gray-900 border-b max-w-s mx-5">
                      Last Updated on {lastUpdate}{" "}
                    </div>
                  </div>
                )}
              </div>

              <div className="my-4 px-8 lg:px-24 sm:py-12 sm:px-14">
                <DataTable
                  columns={TableHeader}
                  data={data}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  expandableRowsHideExpander
                  expandOnRowClicked
                  pagination
                  paginationPerPage={30}
                  responsive
                  paginationComponentOptions={paginationComponentOptions}
                  theme="tableCustom"
                  noDataComponent={noTableDataComponent}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default App;
